import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hire`}</h1>
    <p>{`I've worked across the stack, from building a monolithic applications with Rails, to a fully serverless dropshipping online store.`}</p>
    <p>{`Whether you're looking to outsource some work, or want to discuss how you should build your next project, we should talk.`}</p>
    <p>{`If you need someone who has experienced in the following areas, we `}<em parentName="p">{`really`}</em>{` should talk.`}</p>
    <ul>
      <li parentName="ul">{`Serverless`}</li>
      <li parentName="ul">{`Jamstack`}</li>
      <li parentName="ul">{`Headless APIs`}</li>
      <li parentName="ul">{`JavaScript`}</li>
      <li parentName="ul">{`React, Next.js, Gatsby, and Gatsby plugins`}</li>
      <li parentName="ul">{`Ruby on Rails`}</li>
      <li parentName="ul">{`Content Modelling`}</li>
      <li parentName="ul">{`Video tutorials`}</li>
      <li parentName="ul">{`Documentation`}</li>
      <li parentName="ul">{`SDKs`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      